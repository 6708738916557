import React from "react";
import "./CustomerLogin.css";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import CustomerLogin from "./CustomerLogin";

export default function CustomerPage() {
    return (
        <div>
            <Header />
            {<CustomerLogin />}
            <Footer />
        </div>
    );
}
import http from "../http-common";

class UserService {
    ownerLogin(data) {
        return http.post("/ownerLogin", data);
    }

    deleteOwnerAccount(data) {
        return http.post("/deleteOwnerAccount", data);
    }

    sendOwnerLoginOTP(data) {
        return http.post("/sendOwnerLoginOTP", data);
    }

    customerLogin(data) {
        return http.post("/customerLogin", data);
    }

    deleteCustomerAccount(data) {
        return http.post("/deleteCustomerAccount", data);
    }

    sendCustomerLoginOTP(data) {
        return http.post("/sendCustomerLoginOTP", data);
    }
}

export default new UserService();
import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import smImage from '../../../src/assets/images/about-sm-screen.png';
import ssbImage from '../../../src/assets/images/about-ssb-screen.png';

export default function Home() {
    return (
        <div>
            <Header />
            <div className="container-fluid">
                <div>
                    <div className="mt-3 mb-3">
                        <div className="container-fluid pt-5">
                            <div className="mbr-section-title mbr-fonts-style mb-4 display-5"><strong>Salon
                                Management</strong><strong> &amp; </strong><strong></strong><strong>Salon Slot
                                    Booking </strong><strong>Apps</strong>
                            </div>
                            <p className="mbr-text mbr-fonts-style mb-4 display-7">Salon Management provides two mobile apps through
                                which salon owners and customers can benefit each other.</p>
                            <p className="mbr-text mbr-fonts-style mb-4 display-7">
                                The Salon Management App contains modules for salon enrolment, services management, photo gallery,
                                holidays configuration, shop break timings, special holidays, employee details, and employee working
                                days by week and etc.</p>
                            <p className="mbr-text mbr-fonts-style mb-4 display-7">
                                The Salon Slot Booking App is a user-friendly designed to revolutionize the way people book
                                appointments for salons. This app streamlines the entire process, making it hassle-free for
                                customers and salon owners.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="item-img">
                                <a href="https://salonmanagement.in/">
                                    <img src={smImage} className="img-thumbnail" alt="Salon Management App" />
                                </a>
                            </div>
                            <div className="item-content">
                                <h5 className="item-title mbr-fonts-style display-7">
                                    <strong>Salon Management App</strong></h5>
                            </div>
                        </div>
                        <div className="col">
                            <div className="item-img">
                                <a href="https://salonappointmentbooking.in/">
                                    <img src={ssbImage} className="img-thumbnail" alt="Salon Slot Booking App"
                                        data-slide-to="0" data-bs-slide-to="0" />
                                </a>
                            </div>
                            <div className="item-content">
                                <h5 className="item-title mbr-fonts-style display-7">
                                    <strong>Salon Slot Booking App</strong></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
import React, { useState, useEffect } from "react";
import "./CustomerLogin.css";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/user.service";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../../src/style.css";

export default function CustomerLogin() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOTP] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(30);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    async function sendOTP() {
        setIsLoading(true);
        setErrorMessage('');
        const data = {
            mobileNumber: '+' + phoneNumber
        };

        await UserService.sendCustomerLoginOTP(data).then(async (response) => {
            setIsLoading(false);
            if (response.status === 200) {
                setMinutes(0);
                setSeconds(30);
                setMessage(response.data);
            }
        }).catch((e) => {
            setIsLoading(false);
            setErrorMessage(e.response.data);
        });
    }
    const resendOTP = () => {
        sendOTP();
        setMinutes(0);
        setSeconds(30);
    };

    async function submit() {
        if (phoneNumber == null || phoneNumber.trim === '' || phoneNumber.length !== 12) {
            setErrorMessage('Invalid phone number');
        }
        if (otp == null || otp.trim() === '' || otp.length !== 6) {
            setErrorMessage('Invalid otp');
        }

        else {
            setIsLoading(true);
            setErrorMessage('');
            const data = {
                mobileNumber: '+' + phoneNumber,
                otp: otp
            };

            await UserService.customerLogin(data).then(async (response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    localStorage.setItem('CUSTOMER_AUTH_TOKEN', response.data.token)
                    localStorage.setItem('MOBILE_NUMBER', response.data.mobileNumber)
                    navigate('/deleteCustomerAccount', { replace: true });
                }
            }).catch((e) => {
                setIsLoading(false);
                setErrorMessage(e.response.data.message);
                localStorage.removeItem('CUSTOMER_AUTH_TOKEN');
            });
        }
    }

    function customerLoginContent() {
        return (
            <div className="mt-5">
                <div className="container">
                    <div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column', marginBottom: 10,
                            justifyContent: 'center'
                        }}>
                            <div style={{ justifyContent: 'center', marginBottom: 10 }}>
                                <label id="ownerText"
                                    style={{ textAlign: 'center' }}>Enter the Phone Number used for the Salon Slot Booking App registration</label>
                            </div>
                            <div style={{ justifyContent: 'center' }}>
                                <label id="pnLabel"
                                    style={{ textAlign: 'center', fontWeight: '500' }}>Phone Number</label>
                            </div>
                            <div style={{ justifyContent: 'center', marginBottom: 10 }}>
                                <PhoneInput
                                    containerStyle={{ width: 300 }}
                                    className="number"
                                    country={"in"}
                                    value={phoneNumber}
                                    copyNumbersOnly={true}
                                    placeholder="Enter Mobile Number"
                                    countryCodeEditable={false}
                                    disableDropdown={true}
                                    onChange={(phone) => {
                                        setPhoneNumber(phone)
                                    }}
                                />
                            </div>
                        </div>

                        {isLoading ? <LoadingSpinner /> : null}
                        {errorMessage && (<p className="error"> {errorMessage} </p>)}
                        {message && (<p className="successMessage"> {message} </p>)}

                        {!message && <div>
                            <button onClick={sendOTP} className="btn btn-success" disabled={isLoading}>Send OTP</button>
                        </div>}

                        {message &&
                            <div style={{ margin: 10 }}>
                                <div className="card">
                                    <label className="label2">Verify OTP</label>

                                    <input
                                        placeholder="Enter OTP"
                                        value={otp}
                                        onChange={({ target }) => {
                                            setOTP(target.value);
                                        }}
                                    />

                                    <div className="countdown-text">
                                        {seconds > 0 || minutes > 0 ? (
                                            <p>
                                                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                {seconds < 10 ? `0${seconds}` : seconds}
                                            </p>
                                        ) : (
                                            <p>Didn't recieve code?</p>
                                        )}

                                        <button
                                            disabled={seconds > 0 || minutes > 0}
                                            style={{
                                                color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                                            }}
                                            onClick={resendOTP}
                                        >
                                            Resend OTP
                                        </button>
                                    </div>

                                    <button onClick={submit} className="submit-btn">SUBMIT</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            {customerLoginContent()}
        </div>
    );
}
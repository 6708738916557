import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './Footer.css';
import { SocialIcon } from 'react-social-icons'

export default function Footer() {
    return (
        <div className="mt-5 p-4 bg-dark-blue text-white text-center">
            <Row>
                <Col>
                    <SocialIcon url="https://react-social-icons.com" network="facebook"
                        href="https://www.facebook.com/100092353424441/"
                        bgColor="#3B5998" fgColor="white" style={{ height: 50, width: 50 }} />
                </Col>
                <Col>
                    <SocialIcon url="https://react-social-icons.com" network="instagram"
                        href="https://www.instagram.com/umakathinokkula/"
                        bgColor="#E94475" fgColor="white" style={{ height: 50, width: 50 }} />
                </Col>
                <Col>
                    <SocialIcon url="https://react-social-icons.com" network="youtube"
                        href="https://www.youtube.com/@salonapntbooking/videos"
                        bgColor="#FF3333" fgColor="white" style={{ height: 50, width: 50 }} />
                </Col>
                <Col>
                    <a href="https://api.whatsapp.com/send?phone=919398752916" target='_blank'>
                        <SocialIcon network="whatsapp"
                            label='+919398752916'
                            bgColor="#25D366" fgColor="white" style={{ height: 50, width: 50 }} />
                    </a>

                </Col>
            </Row>
            <Row>
                <div className="bg-neutral-200 pt-4 text-center text-neutral-700 dark:bg-neutral-700 dark:text-neutral-200">
                    © Copyright 2023 Sree Tech Solutions - All Rights Reserved
                </div>
            </Row>
        </div>
    );
}
import React, { useState, useEffect } from "react";
import "./DeleteOwnerAccount.css";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/user.service";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import OwnerLogin from "../ownerLogin/OwnerLogin";
import { Link } from "react-router-dom";
import Footer from "../footer/Footer";
import "../../../src/style.css";
import Header from "../header/Header";

export default function DeleteOwnerAccount() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [isOwnerLoggedIn, setOwnerIsLoggedIn] = useState(
        () => localStorage.getItem('OWNER_AUTH_TOKEN') !== null
    );

    useEffect(() => {
        let loggedIn = localStorage.getItem('OWNER_AUTH_TOKEN') !== null;
        setOwnerIsLoggedIn(loggedIn)
    }, [isOwnerLoggedIn]);

    async function deleteOwnerAccount() {
        var token = window.localStorage.getItem('OWNER_AUTH_TOKEN');
        var mobileNumber = window.localStorage.getItem('MOBILE_NUMBER');

        setIsLoading(true);
        setErrorMessage('');
        setMessage('');
        const data = {
            mobileNumber: mobileNumber,
            token: token
        };

        await UserService.deleteOwnerAccount(data).then(async (response) => {
            setIsLoading(false);
            if (response.status === 200) {
                setErrorMessage('Your data deleted successfully');
                navigate('/home', { replace: true });
            }
        }).catch((e) => {
            setIsLoading(false);
            setErrorMessage('Invalid phone number');
            navigate('/ownerLogin', { replace: true });
        });

    }

    function deleteOwnerAccountContent() {
        return (
            <div className="mt-5">
                <div className="container">
                    <div className="container-box">
                        <div style={{ height: 35, alignContent: "baseline" }}>
                            <label id="pnLabel"
                                style={{ textAlign: 'center', fontSize: 16, fontWeight: 600 }}>Delete Owner Account</label>
                        </div>
                        <div>
                            <label id="pnLabel"
                                style={{ textAlign: 'center', fontSize: 12, fontWeight: 500 }}>Data will be deleted completely. Once data gets deleted, you can't access the account from the Salon Management Mobile App.</label>
                        </div>
                        <br />
                    </div>

                    {isLoading ? <LoadingSpinner /> : null}
                    {errorMessage && (<p className="error"> {errorMessage} </p>)}
                    {message && (<p className="successMessage"> {message} </p>)}

                    <div>
                        <button onClick={deleteOwnerAccount} className="btn btn-success" disabled={isLoading}>Delete Owner Account</button>
                    </div>
                </div>
            </div>
        )
    }

    function homeTabHeader() {
        return (
            <nav className="topnav bg-dark-blue">
                <div className="topnav-right">
                    <Link to={"/home"} className="nav-link">
                        Home
                    </Link>
                </div>
            </nav>
        );
    }

    return (
        <div>
            {isOwnerLoggedIn ? homeTabHeader() : <Header />}
            {isOwnerLoggedIn ? deleteOwnerAccountContent() : <OwnerLogin />}
            <Footer />
        </div>
    );
}
import './App.css';

import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./App.css";
import OwnerPage from './components/ownerLogin/OwnerPage';
import Home from "./components/home/Home";
import CustomerPage from './components/customerLogin/CustomerPage';
import DeleteOwnerAccount from './components/deleteOwnerAccount/DeleteOwnerAccount';
import DeleteCustomerAccount from './components/deleteCustomerAccount/DeleteCustomerAccount';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/customer" element={<CustomerPage />} />
        <Route exact path="/owner" element={<OwnerPage />} />
        <Route exact path="/deleteOwnerAccount" element={<DeleteOwnerAccount />} />
        <Route exact path="/deleteCustomerAccount" element={<DeleteCustomerAccount />} />
      </Routes>
    </div>
  );
}

export default App;
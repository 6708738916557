import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default function Header() {
    return (
        <nav className="topnav bg-dark-blue">
            <div className="topnav-right">
                <Link to={"/home"} className="nav-link">
                    Home
                </Link>
                <Link to={"/customer"} className="nav-link">
                    Customer Login
                </Link>
                <Link to={"/owner"} className="nav-link">
                    Owner Login
                </Link>
            </div>
        </nav>
    );
}
import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import OwnerLogin from "../ownerLogin/OwnerLogin";

export default function OwnerPage() {
    return (
        <div>
            <Header />
            {<OwnerLogin />}
            <Footer />
        </div>
    );
}
